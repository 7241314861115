<template>
  <v-col
    cols="12"
    sm="6"
    md="3"
    class="portfolio__card--item"
    @click="goToDetail(portfolio.id)"
  >
    <!-- <router-link
      :to="{ name: 'portfolioDetail', params: { id: portfolio.id } }"
      class="text-decoration-none"
    > -->
    <div>
      <img :src="portfolio.thumbnail" class="w-100 rounded" alt="blog image" />
    </div>
    <div class="mt-1">
      <v-row>
        <v-col cols="12" sm="8">
          <div class="d-flex align-center mt-1 h-100">
            <div class="name__box">
              <div class="portfolio__name wa__f__m__eb">
                {{ portfolio.title }}
              </div>
              <div class="portfolio__schedule">
                <small>{{ portfolio.created_at }} </small>
                <span class="mx-2">|</span>
                <span>{{ portfolio.location }}</span>
              </div>
            </div>
          </div>
        </v-col>
        <v-col cols="12" sm="4">
          <div class="d-flex align-center justify-end mr-n2">
            <block-button
              text="jp"
              :color="portfolio.verify_jpn ? 'cyan' : 'black'"
              :bgColor="portfolio.verify_jpn ? 'medium-turquoise' : ''"
              class="text-uppercase px-2 py-3 chip__language mr-2"
              x-small
              :loading="loadingVerifyJpn"
              @click.native.stop="verifyLang('jp')"
            ></block-button>
            <block-button
              text="en"
              :color="portfolio.verify_en ? 'cyan' : 'black'"
              :bgColor="portfolio.verify_en ? 'medium-turquoise' : ''"
              class="text-uppercase px-2 py-3 chip__language mr-2"
              x-small
              :loading="loadingVerifyEn"
              @click.native.stop="verifyLang('en')"
            ></block-button>
            <menu-item :items="menuItems" sm />
          </div>
        </v-col>
      </v-row>
      <div class="portfolio__description mb-2">
        {{ portfolio.summary }}
      </div>
      <div>
        <v-card color="grey lighten-3" elevation="0" class="py-1 px-2">
          <div class="d-flex justify-start align-center">
            <div
              class="portfolio__model__included--number"
              v-text="portfolio.users_number"
            ></div>
            <div class="ml-1 models__included">models included</div>
          </div>
        </v-card>
      </div>
      <div class="mt-2 portfolio__tags">
        <v-chip
          v-for="(tag, i) in portfolio.tags"
          :key="i"
          text-color="black"
          color="grey lighten-3"
          label
          class="mr-1"
          small
          >{{ tag }}</v-chip
        >
      </div>
    </div>
    <!-- </router-link> -->
    <v-divider class="mt-1"></v-divider>
  </v-col>
</template>
<script>
import toast from "@/utils/toast";
import { mapActions } from "vuex";
export default {
  data() {
    return {
      loadingVerifyEn: false,
      loadingVerifyJpn: false,
    };
  },
  props: {
    portfolio: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    menuItems() {
      return [
        {
          text: "Translate",
          icon: "WMi-globe",
          click: (self) => {
            self.$_openModal("translate", { model: { id: this.portfolio.id } });
          },
        },
        {
          text: "edit",
          icon: "WMi-pencil",
          route: {
            routeName: "editPortfolio",
            params: {
              locale: this.$_getlocaleParam(),
              id: this.portfolio.id,
            },
          },
        },
        {
          text: "delete",
          icon: "WMi-trash",
          click: () => {
            this.deletePortfolioMessage();
          },
        },
      ];
    },
  },
  methods: {
    async verifyLang(lang) {
      try {
        if (lang === "en") {
          this.loadingVerifyEn = true;
        } else if (lang === "jp") {
          this.loadingVerifyJpn = true;
        }
        const data = { ...this.portfolio, ...{ lang } };
        this.verifyPortfolio(data);
      } catch (error) {
        console.error(error);
        return error;
      } finally {
        this.loadingVerifyJpn = false;
        this.loadingVerifyEn = false;
      }
    },
    ...mapActions("portfolio", ["deletePortfolio", "verifyPortfolio"]),
    goToDetail(id) {
      this.$router.push({
        name: "portfolioDetail",
        params: { id, locale: this.$_getlocaleParam() },
      });
    },
    deletePortfolioMessage() {
      toast.question(
          this.$_trans("toast.del_portfolio_item_msg"),
          this.$_trans("toast.del_portfolio_item_title"),
        () => {
          this.deletePortfolio(this.portfolio.id);
        }
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.portfolio__card--item {
  cursor: pointer !important;
}
.name__box {
  line-height: 14px;
  display: grid;
}
.portfolio__name {
  font-family: "Montserrat-regular";
  font-size: 14px;
  color: var(--color-black);
  white-space: nowrap;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
}
.portfolio__schedule {
  font-size: 10px;
  color: #898989;
}
.chip__language {
  font-family: "Barlow-EL";
  font-size: 16px;
  margin-right: 1px !important;
}
.portfolio__description {
  font-size: 10px;
  color: #414141;
  text-align: justify;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  display: -webkit-box;
}
.portfolio__model__included--number {
  font-family: "Montserrat-regular";
  font-size: 18px;
}
.models__included {
  font-family: "montserrat-light";
  font-size: 9px;
  color: #8b8b8b;
  width: min-content;
  line-height: 8px;
}
.portfolio__tags {
  height: 28px;
  line-height: 26px;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  display: -webkit-box;
}
</style>
