<template>
  <list title="contetns" class="wa__large">
    <hr class="mt-0" />
    <template #header-btn>
      <router-link
        :to="{ name: 'addPortfolio', params: { locale: $_getlocaleParam() } }"
        class="text-decoration-none"
      >
        <block-button
          text="add"
          icon="WMi-plus-linear"
          size="small"
          height="37"
          class="px-10 btn__size__18 site__button add__mode width-200 add__mode mt-n1"
          @click.native="$_openModal('add_portfolio')"
        ></block-button>
      </router-link>
    </template>
    <data-iterator
      :is-filtered="isFilteredPortfolio"
      :loading="getPortfolioLoading"
      :items="getPortfolios"
      @pagination="changePagination"
      :pagination="getPaginationPortfolio"
      class="row portfolio__card pb-10"
    >
      <template #loading>
        <loading />
      </template>
      <template #no-items>
        <no-items />
      </template>
      <template #items="{ item }">
        <PortfolioItem :portfolio="item" />
      </template>
    </data-iterator>
    <!-- --------------------------------------------------------------------
                            Modals :: BEGIN
        ---------------------------------------------------------------------->
    <template>
      <div class="text-center">
        <PortfolioTranslateModal v-if="isModal('modal_translate')" />
      </div>
    </template>
    <!-- --------------------------------------------------------------------
                                    Modals :: END
        ---------------------------------------------------------------------->
  </list>
</template>

<script>
import PortfolioItem from "./Item";
import PortfolioTranslateModal from "./Modal/Translate.vue";
import { mapActions,mapMutations, mapGetters } from "vuex";
export default {
  name: "portfolio_items",
  components: {
    PortfolioItem,
    PortfolioTranslateModal,
  },
  computed: {
    ...mapGetters("modal", ["isModal"]),
    ...mapGetters("portfolio", [
      "getPortfolios",
      "getPortfolioLoading",
      "getPaginationPortfolio",
      "isFilteredPortfolio",
    ]),
  },
  methods: {
    ...mapActions("portfolio", ["loadPortfolios"]),
    ...mapMutations("portfolio", ["SET_PAGINATION"]),
    changePagination(pagination) {
      if (this.getPaginationPortfolio.page !== pagination.page) {
          this.SET_PAGINATION(pagination);
          this.loadPortfolios();
      }
    },
  },
  created() {
    this.loadPortfolios();
  },
};
</script>

<style scoped>
.portfolio__card {
  font-family: "Montserrat-light";
}
</style>
