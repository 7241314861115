<template>
  <basic-modal @open="setData" width="1250" transition="slide-x-transition">
    <template #header>
      <divider
        title="Portfolio translate"
        sub-title="make it local"
        size="small"
      ></divider>
    </template>
    <template #default>
      <div class="mt-4 px-15 pr-13">
        <v-row>
          <v-col cols="12" sm="6" class="pb-0">
            <v-text-field
              label="title"
              color="SonicSilver"
              class="mt-0 pt-0"
              dense
              outlined
              v-model="form.title_jpn"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" class="pb-0">
            <v-text-field
              label="project location"
              color="SonicSilver"
              class="mt-0 pt-0"
              dense
              outlined
              v-model="form.location_jpn"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="pt-0">
            <v-textarea
              color="SonicSilver"
              rows="3"
              label="summary"
              dense
              outlined
              v-model="form.summary_jpn"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col cols="12">
            <v-combobox
              clearable
              :append-icon="null"
              hide-selected
              v-model="form.tags"
              label="tags"
              outlined
              color="SonicSilver"
              dense
              multiple
              persistent-hint
              small-chips
            >
              <template #selection="{ item }">
                <v-chip
                  close
                  @click:close="removeTags(item)"
                  class="mt-1 chip__tag"
                  text-color="black"
                  label
                  small
                >
                  {{ item }}
                </v-chip>
              </template>
            </v-combobox>
          </v-col>
        </v-row>
      </div>
    </template>
    <template #footer>
      <v-spacer></v-spacer>
      <div class="px-10 pt-2 pb-3 d-flex justify-space-between align-items-center w-100">
        <block-button
          class="btn__size__18 px-3"
          height="30"
          text="cancel"
          @click.native="$_closeModal()"
        ></block-button>
        <block-button
          height="30"
          class="btn__modal--assign btn__size__16 site__button width-200 ml-5"
          text="translate"
          icon="WMi-plus"
          :loading="loadingSendData"
          @click.native="translatePortfolio"
        ></block-button>
      </div>
    </template>
  </basic-modal>
</template>
<script>
import PortfolioRepository from "@/abstraction/repository/portfolio/portfolioRepository";
import { mapActions } from "vuex";
const portfolioRepository = new PortfolioRepository();
export default {
  name: "modal_translate",
  data: () => ({
    form: {},
    loadingSendData: false,
  }),
  methods: {
    ...mapActions("portfolio", ["updatePortfolio"]),
    removeTags(item) {
      this.form.tags.splice(this.form.tags.indexOf(item), 1);
      this.form.tags = [...this.form.tags];
    },
    setData({ model }) {
      if (model.id) {
        this.loadPortfolioById(model.id);
      }
    },
    async loadPortfolioById(id) {
      this.form = await portfolioRepository.show(id);
    },
    async translatePortfolio() {
      try {
        this.loadingSendData = true;
        let response = await this.updatePortfolio(this.form);
        if (!(response instanceof Error)) {
          this.$_closeModal();
        }
      } catch (error) {
        console.error(error);
        return error;
      } finally {
        this.loadingSendData = false;
      }
    },
  },
};
</script>
<style scoped>
.btn__modal--assign {
  padding: 20px 35px !important;
  font-family: "Montserrat-ExtraLight";
}
.chip__tag {
  font-family: "montserrat-regular";
  font-size: 12px;
}
</style>
