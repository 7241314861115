<template>
  <div class="wa__container">
    <header class="pt-3">
      <div class="pt-3">
        <v-row>
          <v-col cols="12">
            <site-header
              backRouteName="dashboard"
              backText="home"
              title="Portfolios"
              sub-title="what we shared"
            ></site-header>
          </v-col>
        </v-row>
      </div>
    </header>
    <main>
      <div>
        <v-row class="justify-content-center pb-4 wa__models">
          <v-col cols="12" md="2">
            <PortfolioFilter />
          </v-col>
          <v-col cols="12" md="10">
            <PortfolioItems />
          </v-col>
        </v-row>
      </div>
    </main>
    <footer>
      <div class="wa__home--footer">
        <site-footer text="all Right reserved"></site-footer>
      </div>
    </footer>
  </div>
</template>
<script>
import PortfolioItems from "@/components/Portfolio/Items";
import PortfolioFilter from "@/components/Portfolio/Filter";
import {mapMutations} from "vuex"
export default {
  name: "portfolio",
  components: {
    PortfolioFilter,
    PortfolioItems,
  },
  methods: {
    ...mapMutations('portfolio', ['DESTROY_HISTORY']),
  },
  destroyed(){
    this.DESTROY_HISTORY()
  }
};
</script>
